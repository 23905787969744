import {
  primary,
  secondary,
  complementary,
  darkGray,
  redAlert,
  white,
  black,
  appBarBoxShadow,
} from "./color-palettes";

/**
 * CONTRACT: This impl will be split into diff files the moment
 * the number of components in the definition equals to five (5).
 * Right now the count is equal to one (1)
 */
export const components = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 28,
        // fontSize: '1rem',
        fontWeight: 600,
        boxShadow: "none",
        lineHeight: 1.2,
        textTransform: "none",
        width: "100%",
        "&:hover": {
          boxShadow: "none",
        },
        "&:focus": {
          outlineColor: "inherit",
        },
        "&:disabled": {
          border: "1px solid",
        },
      },
    },
    // variants: [
    //   {
    //     props: { variant: 'contained' },
    //     style: {
    //       boderRadius: 28,
    //     }
    //   }
    // ]
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        "&:hover": {
          boxShadow: "none",
          backgroundColor: "transparent",
        },
        "&:focus": {
          outlineColor: "inherit",
          outline: "none",
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      InputLabelProps: { shrink: true },
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      notched: true,
    },
    styleOverrides: {
      root: {
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          top: 0,
          borderColor: black[800],
        },
        "& input[readonly] + fieldset, & input[readonly]:focus + fieldset, & input[readonly]:hover + fieldset":
          {
            borderColor: black[200],
          },
        "&.Mui-disabled": {
          "& fieldset": {
            borderColor: black[200],
          },
          "& .MuiInputBase-input.Mui-disabled": {
            "-webkit-text-fill-color": black[700],
            //color: primary.main,
          },
        },
      },
      input: {
        padding: "5px 8px !important",
        fontSize: 14,
        width: "100%",
        height: "2.0em",
        "&::placeholder": {
          padding: "5px 1px !important",
        },
      },
      notchedOutline: {
        "& > legend > span": {
          display: "none",
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        margin: "0px 10px",
      },
    },
    defaultProps: {
      style: {
        fontSize: 12,
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      InputLabelProps: { shrink: true },
    },
    styleOverrides: {
      root: {
        width: "100%",
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...{
          fontSize: 16,
          fontWeight: 500,
          position: "relative",
          margin: 0,
          transform: "translate(0px, 3px) scale(0.75)",
          // color: theme.palette.primary.main,
          [theme.breakpoints.up("md")]: {
            fontSize: 16,
          },
          zIndex: 0,
        },
        "&.Mui-disabled": {
          color: black[700],
        },
      }),
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {},
      sizeSmall: {
        height: "32px",
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      InputLabelProps: { shrink: true },
    },
    styleOverrides: {
      root: ({ theme }) => ({
        paddingLeft: "4px",
        paddingRight: "4px",
        fontSize: 14,
        width: "100%",
        "& .MuiSvgIcon-root": {
          fontSize: "2.5rem",
          color: primary.main,
          top: "3px",
        },
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          height: 32,
          maxHeight: 32,
          top: -1,
        },

        "& .MuiInputBase-input": {
          borderRadius: 4,
          padding: "0px 8px",
          "&:focus": {
            borderRadius: 4,
          },
        },
        "&& .MuiInputBase-root": {
          maxHeight: "32px",
        },
      }),
      select: {
        width: "90%",
        padding: "0px 8px !important",
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        height: 26,
        "&.MuiMenuItem-root": {
          fontSize: 14,
        },
        "&:hover": {
          fontWeight: 400,
        },
        "&.MuiMenuItem-root.Mui-selected": {
          // backgroundColor: "#006241",
          // color: "#f9f9f9",
          "&:hover": {
            // backgroundColor: "#006241",
          },
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...{
          padding: 0,
          paddingRight: "5px",
          transform: "scale(1.5)",
          "&.Mui-checked": {
            color: darkGray.main,
          },
        },
      }),
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        width: "100%",
        "&.formControl": {
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            height: "auto",
            maxHeight: 38,
            top: 0,
          },
          "& .MuiAutocomplete-input": {
            paddingTop: "2px !important",
            paddingBottom: "2px !important",
          },
          "& .MuiAutocomplete-inputRoot": {
            height: "inherit",
          },
        },
        "& .MuiTextField-root": {
          margin: "0px !important",
        },
        "& .MuiOutlinedInput-root": {
          padding: "5px 8px",
        },
        "& fieldset.MuiOutlinedInput-notchedOutline": {
          height: 32,
          maxHeight: 32,
          top: -1,
        },
        "& > .MuiBox-root > .clearButton": {
          position: "relative",
          float: "right",
          right: "20px",
          top: "-40px",
          cursor: "pointer",

          fontSize: 14,
          "&:focus": {
            outline: 0,
          },
          "& > .MuiSvgIcon-root": {
            color: `${redAlert[900]} !important`,
            fontSize: "2rem",
          },
        },
        "& .MuiSvgIcon-root": {
          fontSize: "2.5rem",
          color: primary.main,
        },
      },
      popupIndicator: {
        fontSize: "2.5rem",
        color: primary.main,
        top: 0,
      },
      input: {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: `${primary.main} !important`,
        },
        "& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover": {
          backgroundColor: `${primary.main} !important`,
          color: complementary.contrastText,
        },
        "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
          {
            backgroundColor: `${primary.main} !important`,
            color: complementary.contrastText,
          },
      },
      endAdornment: {
        transform: "translate(0, -50%)",
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...{
          color: theme.palette.redAlert.main,
          "& > .actionContainer": {
            display: "flex",
            justifyContent: "flex-end",
            flex: 1,
            gap: "10px",
            "& > button": {
              maxWidth: 130,
              width: 130,
              [theme.breakpoints.down("sm")]: {
                maxWidth: "100%",
                width: "100%",
              },
            },
          },
        },
      }),
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: white.main,
        boxShadow: `0 1px 3px ${black[900]}${appBarBoxShadow.tp0},0 2px 2px ${black[900]}${appBarBoxShadow.tp1},0 0 2px ${black[900]}${appBarBoxShadow.tp2}`,
        color: black.main,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minWidth: 60,
        minHeight: "32px",
        height: "32px",
        display: "block",
        width: 100,
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: {
        align: "left",
        backgroundColor: darkGray[200],
      },
      selectIcon: {
        right: "-10px",
      },
      selectLabel: {
        paddingTop: 3,
      },
      displayedRows: {
        paddingTop: 0,
        fontSize: 13,
      },
      toolbar: {
        padding: 0,
        paddingLeft: 8,
        display: "inline-flex",
      },
      actions: {
        "& > .MuiIconButton-root": {
          backgroundColor: darkGray[200],
          color: darkGray.main,
        },
        "& > .MuiIconButton-root.Mui-disabled": {
          color: darkGray[400],
        },
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        outline: "none",
        border: "0",

        "& .MuiSvgIcon-root": {
          fontSize: 21,
        },
        //Rows selection dropdown box alignment
        "& .MuiInputBase-root": {
          paddingTop: 2,
          // fontSize: 13,
        },
        //Sort Button hover and margin alginment
        "& .MuiIconButton-root": {
          marginRight: 10,
          color: white.main,
        },

        "& .MuiIconButton-root:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: "500",
          fontSize: 15,
          color: white.neutral,
        },
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          padding: "1px 0 0 0",
        },
        "*::-webkit-scrollbar": {
          width: "0.8em",
          height: "0.6em",
        },
        "& ::-webkit-scrollbar-track": {
          background: "#ffff",
        },
        "& ::-webkit-scrollbar-thumb": {
          background: "#1a7254",
          borderRadius: "5px",
        },
        "& .MuiDataGrid-scrollbarFiller": {
          // background: "#1a7254",
          minWidth: "unset",
        },
        "& .MuiDataGrid-filler": {
          borderBottom: "none !important",
        },
      },
      columnHeader: {
        padding: "0 6px",
        backgroundColor: primary.main,
        backgroundImage: `linear-gradient(${primary.main}, #1a7254)`,
        height: "auto !important",
        borderBottom: "0px !important",
        "& + :hover .MuiDataGrid-menuIcon": {
          visibility: "hidden",
        },
        "& + :first-of-type": {
          padding: "0 6px",
          borderRight: "1px solid white",
          lineHeight: 56,
        },
        "+ .MuiDataGrid-filler": {
          /* next sibling */
          backgroundImage: `linear-gradient(${primary.main}, #1a7254)`,
        },
      },
      columnHeaders: {
        backgroundImage: `linear-gradient(${primary.main}, #1a7254)`,
        backgroundColor: primary.main,
        fontWeight: 500,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        paddingTop: 4,
        paddingBottom: 4,
      },
      row: {
        borderTop: "1px solid",
        borderTopColor: "rgba(0, 0, 0, 0.12)",
        "& + :hover": {
          backgroundColor: "rgb(204, 204, 204, 0.5)",
          backgroundImage: `linear-gradient(${darkGray[300]}, #e6e6e6)`,
        },
        "& + .Mui-selected": {
          backgroundColor: "inherit",
        },
        "&.odd": {
          backgroundColor: "rgba(0,98,68,.05)",
          backgroundImage: `linear-gradient(${secondary.main}, #ebf3f0)`,
        },
        "&.odd:hover": {
          backgroundColor: "rgb(204, 204, 204, 0.5) !important",
          backgroundImage: `linear-gradient(${darkGray[300]}, #e6e6e6) !important`,
        },
        "&.clickable": {
          cursor: "pointer",
        },
      },
      cell: {
        minHeight: `32px !important`,
        fontSize: 13,
        borderBottom: "0px",
        // padding: "0 6px",
        "&:focus-within": {
          outline: "none",
        },
        "&:focus": {
          outline: "none",
        },
      },
      overlay: {
        fontSize: 12,
      },
      sortIcon: {
        fontSize: 18,
      },
      toolbarQuickFilter: ({ theme }) => ({
        ...{
          marginRight: `${theme.spacing(1)} !important`,
          maxWidth: theme.spacing(27),
          "& > .MuiInputBase-formControl > .MuiInputBase-inputTypeSearch": {
            height: "1.5em",
          },
        },
      }),
    },
  },
};
